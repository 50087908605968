/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export type EdgeValues = {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
  any: boolean;
  all: boolean;
};

export class EdgeDetectUtils {
  public static checkEdges(element: HTMLDivElement): EdgeValues {
    // Get element's bounding
    const bounding = element.getBoundingClientRect();
    return this.checkEdgesBounding(bounding);
  }

  public static checkEdgesBounding(bounding: DOMRect): EdgeValues {
    const out = { top: false, left: false, bottom: false, right: false, any: false, all: false };
    out.top = bounding.top < 0;
    out.left = bounding.left - 72 < 0; // subtract side bar width
    out.bottom = bounding.top + bounding.height > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.left + bounding.width > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;
    return out;
  }
}
